import React from "react";
import classes from "./FeedingsList.module.css";

const toListItem = feeding => (
  <li className={classes.ListItem} key={feeding.time}>
    {format(feeding)}
  </li>
);

const FeedingsList = ({ feedings }) => {
  const sortedFeedings = feedings.slice().sort(mostRecentFirst);
  return (
    <>
      <h2 className={classes.Label}>Recent Feedings</h2>
      <ul className={classes.List}>{sortedFeedings.map(toListItem)}</ul>
    </>
  );
};

export default FeedingsList;

function mostRecentFirst(a, b) {
  return b.time - a.time;
}

// Given a feeding (timestamps are values generated by Date.prototype.getTime), return a string
// representation of the feeding time
function format(feeding) {
  return new Date(feeding.time).toLocaleString(undefined, {
    dateStyle: "short",
    timeStyle: "short"
  });
}
