import React from "react";

import classes from "./MilkButton.module.css";

const MilkButton = ({ feedings, isLoading, onClick }) => {
  const nip = isLoading ? (
    <div className={classes.loader}>Loading...</div>
  ) : (
    <button className={classes.Button} />
  );
  return (
    <div className={classes.Container} onClick={onClick}>
      {nip}
    </div>
  );
};

export default MilkButton;
