import React from "react";

import AgeTracker from "./components/AgeTracker/AgeTracker";
import MilkTracker from "./containers/MilkTracker";

import classes from "./App.module.css";
import "./global.css";

function App() {
  return (
    <div className={classes.App}>
      <header className={classes.AppHeader}>
        <h1>
          <span className={classes.nope}>what</span>{" "}
          <span className={classes.nope}>where</span> when{" "}
          <span className={classes.nope}>who</span>
        </h1>
      </header>

      <AgeTracker />
      <MilkTracker />
    </div>
  );
}

export default App;
