/*
 * Why are these capitalized? Because `delete` is a keyword in javascript and
 * cannot be used for a function name.
 */

const basePath = "https://feeding-tracker-37ebf.firebaseio.com/";

export function Get(resource) {
  return fetch(basePath + resource).then(response => response.json());
}

export function Post(resource, data) {
  const body = JSON.stringify(data);

  return fetch(basePath + resource, {
    method: "POST",
    body
  }).then(response => response.json());
}

export function Delete(resource, id) {
  const body = JSON.stringify(id);

  return fetch(basePath + resource, {
    method: "DELETE",
    body
  }).then(r => r.json());
}
