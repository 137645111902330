import React from "react";
import { timeDay } from "d3-time";

import classes from "./AgeTracker.module.css";

const weeksAndDaysBetween = (start, finish) => {
  const totalDays = timeDay.count(start, finish);
  const weeks = Math.floor(totalDays / 7);
  const days = totalDays - weeks * 7;

  return [weeks, days];
};

// as of 1/26/20 the age should be 14 weeks, 2 days
const AgeTracker = () => {
  const birthdate = new Date("10/18/2019 4:22:00 PM PDT");
  const now = new Date();
  const [weeks, days] = weeksAndDaysBetween(birthdate, now);
  const formattedWeeks = `${weeks} weeks`;
  const formattedDays =
    days === 0 ? "" : days > 1 ? `, ${days} days` : `, ${days} day`;

  return (
    <div className={classes.Tracker}>
      {formattedWeeks}
      {formattedDays} old
    </div>
  );
};

export default AgeTracker;
