import React, { Component } from "react";

import { Get, Post } from "../api";
import MilkButton from "../components/MilkButton/MilkButton";
import FeedingsList from "../components/FeedingsList/FeedingsList";

const endpoint =
  process.env.NODE_ENV === "production"
    ? "feedings.json"
    : "test-feedings.json";

class MilkTracker extends Component {
  state = {
    feedings: [],
    isLoading: true
  };

  // When the component first mounts, fetch the feedings from firebase. Inside
  // the `for/in` loop, a call to `Delete(endpoint, id)` can be used to clear
  // out the test database.
  componentDidMount() {
    Get(endpoint)
      .then(data => {
        const feedings = [];
        for (const id in data) {
          feedings.push({ ...data[id], id });
        }
        this.setState({ feedings, isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        console.error(e.message);
      });
  }

  // The first time the button is clicked, the user is not feeding; the
  // beginning time is recorded in `this.state.currentFeeding` and
  // `this.state.isFeeding` is set to `true`. The next time the button is
  // clicked, `this.state.isFeeding` is toggled off, `this.state.currentFeeding`
  // is first completed by adding the end time and then stored in both
  // `this.state.feedings` and the firebase backend, and the state is reset as
  // is was before the first click.
  milkButtonClicked = () => {
    const currentFeeding = {
      time: new Date().getTime()
    };

    this.setState({ isLoading: true });

    Post(endpoint, currentFeeding)
      .then(recordConfirmation => {
        currentFeeding.id = recordConfirmation.name;

        const feedings = this.state.feedings.concat([currentFeeding]);

        this.setState({ feedings, isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        console.error(e.message);
      });
  };

  render() {
    return (
      <>
        <MilkButton
          feedings={this.state.feedings}
          isLoading={this.state.isLoading}
          onClick={this.milkButtonClicked}
        />
        <FeedingsList feedings={this.state.feedings} />
      </>
    );
  }
}

export default MilkTracker;
